import React from "react";
import * as S from "./styles";

export const Hightlights: React.FC = () => {
  const now = new Date(); // Data atual
  // Primeiro dia do mês (sempre dia 1)
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

  // Último dia do mês (dia 0 do próximo mês retorna o último dia do mês atual)
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Função para formatar data como "dia/mês/ano"
  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, "0"); // Dia com 2 dígitos
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês com 2 dígitos
    const year = date.getFullYear(); // Ano completo
    return `${day}/${month}/${year}`;
  };

  console.error("valor de fristDay: ", firstDay);
  return (
    <>
      <S.Container>
        <S.Content>
          <S.GroupTitle>
            <S.Title>
              Regulamento Cupom de desconto <span>ORTHOPRIDE</span>
            </S.Title>
          </S.GroupTitle>
          <S.Topic>1. Das condições de participação: </S.Topic>
          <S.Text>
            1.1. Os clientes que receberem o cupom individual, seja por e-mail,
            whatsapp ou panfleto, terão direito a um desconto indicado na
            imagem, que será aplicado a compra, exclusivamente sobre novos
            produtos da rede Orthopride, de acordo com as seguintes condições:
          </S.Text>
          <S.Text>
            1.2. Apenas um cupom poderá ser utilizado por tratamento.
          </S.Text>
          <S.Text>
            1.3. O benefício do cupom de desconto é válido para compras
            aprovadas com qualquer forma de pagamento aceita pela Orthopride.
          </S.Text>
          <S.Text>
            1.4. O desconto é válido somente para novas compras aprovadas pelo
            sistema Orthopride.
          </S.Text>
          <S.Text>
            1.5. Os cupons não são cumulativos entre si, não sendo possível a
            utilização de dois cupons numa mesma compra.
          </S.Text>
          <S.Topic>2. Da duração da promoção: </S.Topic>
          <S.Text>
            2.1. A promoção válida de {formatDate(firstDay)} até{" "}
            {formatDate(lastDay)}.{" "}
          </S.Text>
          <S.Topic>3. Regras gerais: </S.Topic>
          <S.Text>
            3.1. O desconto disponível no cupom não poderá ultrapassar os
            valores mínimos dos tratamentos e produtos estabelecidos pela
            unidade Orthopride.
          </S.Text>
          <S.Text>
            3.2. O desconto somente poderá ser utilizado caso seja apresentado
            pelo beneficiário.
          </S.Text>
          <S.Text>
            3.3. A adimplência do beneficiário nos produtos e tratamentos é
            condição essencial para validade do desconto aplicado.
          </S.Text>
          <S.Topic>4. Das disposições finais: </S.Topic>
          <S.Text>
            4.1. A participação na promoção implica a aceitação total e
            irrestrita pelo Participante de todos os itens deste Regulamento,
            seus termos e condições.{" "}
          </S.Text>
        </S.Content>
      </S.Container>
    </>
  );
};
